import React, { useEffect, useState } from "react";
import { getAboutUs } from "../../services/aboutUsService";
import { useParams } from "react-router-dom";
import { getBannerImages } from "../../services/api";

import coverImage from "../../assets/img/cover.jpg";

import Accessibility from "./Accessibility";
import Modal from "react-modal";

const AboutUs = () => {
  const [currentLocale, setcurrentLocale] = useState("EN");
  const [aboutUsImages, setAboutUsImages] = useState({
    aboutUsHistory: "",
    aboutUsIntroduction: "",
    aboutUsOurTeam: "",
  });

  const [fieldValue, setFieldValue] = useState([]);
  const { fieldName } = useParams();
  const [locale, setLocale] = useState("eng");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  useEffect(() => {
    const fetchFieldData = async (field, locale = "") => {
      try {
        const resp = await getAboutUs(field + locale);
        const data = resp.data.data;
        setFieldValue(data[field + locale]);
      } catch (error) {}
    };

    if (locale === "eng") {
      fetchFieldData(fieldName);
    } else if (locale === "nep") {
      fetchFieldData(fieldName, "Nepali");
    }
  }, [fieldName]);

  useEffect(() => {
    fetchData();
    let currentLocale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setcurrentLocale(currentLocale);
  }, []);

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;

      let aboutusData = {
        aboutUsHistory: "",
        aboutUsIntroduction: "",
        aboutUsOurTeam: "",
      };

      aboutusData.aboutUsHistory = resData.aboutUsHistory;
      aboutusData.aboutUsIntroduction = resData.aboutUsIntroduction;
      aboutusData.aboutUsOurTeam = resData.aboutUsOurTeam;
      setAboutUsImages(aboutusData);
    } catch (error) {}
  };

  const roles = [
    {
      role: "Board Member",
      type: "Board Member",
      labelEN: "Board Members",
      labelNP: "बोर्ड सदस्यहरू",
    },
    {
      role: "Core Team",
      type: "Core Team",
      labelEN: "Core Team",
      labelNP: "कोर टोली",
    },
    {
      role: "Project Staff and Consultants",
      type: "Project Staff and Consultants",
      labelEN: "Project Staff and Consultants",
      labelNP: "परियोजना कर्मचारी र परामर्शदाताहरू",
    },

    // Add new roles here
  ];

  const groupedData = groupDataByRoleType(fieldValue, roles);

  const openModal = (member) => {
    setSelectedTeamMember(member);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTeamMember(null);
  };

  return (
    <>
      <section className="about-us-section">
        <Accessibility />

        {fieldName === "whoWeAre" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                aboutUsImages && aboutUsImages.aboutUsIntroduction
                  ? aboutUsImages.aboutUsIntroduction
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {currentLocale === "EN" ? "About Koshish" : "कोशिश बारेमा"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "boardCommittees" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                aboutUsImages && aboutUsImages.aboutUsOurTeam
                  ? aboutUsImages.aboutUsOurTeam
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {currentLocale === "EN" ? "Our Team" : "हाम्रा टोलीहरू"}
              </h1>
            </div>
          </div>
        )}

        {fieldName !== "boardCommittees" && (
          <div className="container">
            {fieldName === "history" && (
              <h1 className="text-center text-blue">
                {currentLocale === "EN" ? "History" : "इतिहास"}{" "}
              </h1>
            )}
            {fieldName === "whoWeAre" && (
              <h1 className="text-center text-blue">
                {currentLocale === "EN" ? "Introduction" : "परिचय"}{" "}
              </h1>
            )}
            {fieldName === "boardCommittees" && (
              <h1 className="text-center text-blue">
                {currentLocale === "EN" ? "Our Team" : "हाम्रा टोलीहरू"}{" "}
              </h1>
            )}

            {fieldName !== "boardCommittees" && (
              <p dangerouslySetInnerHTML={{ __html: fieldValue }}></p>
            )}
          </div>
        )}

        {fieldName === "boardCommittees" &&
          roles.map((role) => {
            // Filter and sort data for the current role
            const roleData = (groupedData[role.type] || [])
              .slice()
              .sort((a, b) => {
                const indexA = parseInt(a.indexNo, 10) || 0;
                const indexB = parseInt(b.indexNo, 10) || 0;
                return indexA - indexB;
              });

            return (
              <div className="container" key={role.type}>
                <h2 className="font-weight-bold mt-5 text-blue">
                  {currentLocale === "EN" ? role.labelEN : role.labelNP}
                </h2>
                <div className="row mt-4">
                  {roleData.map((item, index) => (
                    <div
                      key={index}
                      className="col-12 col-md-4 col-xl-2 mb-3"
                      onClick={() => openModal(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="team-member d-flex flex-column align-items-center">
                        <img
                          src={item.photo}
                          alt="team member"
                          className="img-fluid our-team-image"
                        />
                        <div className="team-member-info">
                          <h5 className="font-weight-bold text-blue text-center">
                            {currentLocale === "EN"
                              ? item.name
                              : item.nameNepali}
                          </h5>
                          <div
                            className="text-center"
                            style={{ color: "black" }}
                          >
                            {currentLocale === "EN"
                              ? item.position
                              : item.positionNepali}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border-bottom mt-4 mb-4"></div>
              </div>
            );
          })}
      </section>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Team Member Modal"
        style={{
          content: {
            width: "60%",
            height: "400px",
            margin: "auto",
            marginTop: "150px",
            position: "relative",
            padding: "20px",
            overflow: "auto",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        {selectedTeamMember && (
          <div className="row mt-5">
            <div className="col-12 col-lg-4 d-flex flex-column align-items-center">
              <img
                src={selectedTeamMember.photo}
                alt="team member"
                style={{ width: 250, height: 250 }}
              />
              <h4 className="font-weight-bold text-blue mt-2">
                {currentLocale === "EN"
                  ? selectedTeamMember.name
                  : selectedTeamMember.nameNepali}
              </h4>
            </div>
            <div className="col-12 col-lg-8 d-flex align-items-center">
              <p className="text-left mt-2">
                {currentLocale === "EN"
                  ? selectedTeamMember.description
                  : selectedTeamMember.descriptionNepali}
              </p>
            </div>
          </div>
        )}
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={closeModal}
        >
          X
        </button>
      </Modal>
    </>
  );
};

export default AboutUs;

const groupDataByRoleType = (data, roles) => {
  return roles.reduce((acc, role) => {
    acc[role.type] = data.filter((item) => item.role === role.role);
    return acc;
  }, {});
};
