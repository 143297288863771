import React from "react";
import { Link, NavLink } from "react-router-dom";

function AdminSidebar() {
  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <div className="d-flex">
            <div style={{ marginRight: "10px" }}>
              <i className="bi bi-menu-button-wide "></i>
            </div>
            <h4>Components</h4>
          </div>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/branding">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Branding</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/needhelp">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Need Help</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/mentalHealth">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Mental Health</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/popup">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Popup Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Dashboard</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </NavLink>
            <ul
              id="components-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <NavLink to="/admin/slider">
                  <i className="bi bi-circle"></i>
                  <span>Slider</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/stories">
                  <i className="bi bi-circle"></i>
                  <span>Stories</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/ourImpact">
                  <i className="bi bi-circle"></i>
                  <span>Our Impact</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/ourWork">
                  <i className="bi bi-circle"></i>
                  <span>Our Work</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/ourValues">
                  <i className="bi bi-circle"></i>
                  <span>Our Values</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/theJourney">
                  <i className="bi bi-circle"></i>
                  <span>The Journey</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/geocolor">
                  <i className="bi bi-circle"></i>
                  <span>The Journey Colors</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/geoCoverage">
                  <i className="bi bi-circle"></i>
                  <span>Geographical Coverage</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/ourPartners">
                  <i className="bi bi-circle"></i>
                  <span>Our Partners</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/yourSupport">
                  <i className="bi bi-circle"></i>
                  <span>Your Support</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/latestnews">
                  <i className="bi bi-circle"></i>
                  <span>Latest News</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/contactUs">
                  <i className="bi bi-circle"></i>
                  <span>Contact Us</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/nlgmf">
                  <i className="bi bi-circle"></i>
                  <span>Send News Letter</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/strategicPlan">
                  <i className="bi bi-circle"></i>
                  <span>Our Strategic Plan</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              data-bs-target="#Pages-nav"
              data-bs-toggle="collapse"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Pages</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </NavLink>
            <ul
              id="Pages-nav"
              className="nav-content collapse "
              data-bs-parent="#Pages-nav"
            >
              <li>
                <Link
                  className="nav-link collapsed"
                  data-bs-target="#AboutUs-nav"
                  data-bs-toggle="collapse"
                  to="#"
                >
                  <i className="bi bi-journal-text"></i>
                  <span>About Us</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  id="AboutUs-nav"
                  className="nav-content collapse "
                  data-bs-parent="#AboutUs-nav"
                >
                  <li>
                    <NavLink to="/admin/aboutUs">
                      <i className="bi bi-circle"></i>
                      <span>About Us</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/aboutUsHistory">
                      <i className="bi bi-circle"></i>
                      <span>About Us History</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/aboutUsIntroduction">
                      <i className="bi bi-circle"></i>
                      <span>About Us Introduction</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/aboutUsThematicArea">
                      <i className="bi bi-circle"></i>
                      <span>About Us Thematic Areas</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/aboutUsOurValues">
                      <i className="bi bi-circle"></i>
                      <span>About Us Our Values</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/aboutUsOurApproach">
                      <i className="bi bi-circle"></i>
                      <span>About Us Our Approach</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/admin/ourProgram">
                  <i className="bi bi-circle"></i>
                  <span>Our Work</span>
                </NavLink>
              </li>

              <li>
                <Link
                  className="nav-link collapsed"
                  data-bs-target="#resources-nav"
                  data-bs-toggle="collapse"
                  to="#"
                >
                  <i className="bi bi-journal-text"></i>
                  <span>Resources</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  id="resources-nav"
                  className="nav-content collapse"
                  data-bs-parent="#resources-nav"
                >
                  <li>
                    <NavLink to="/admin/resources">
                      <i className="bi bi-circle"></i>
                      <span>Resource</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/publication">
                      <i className="bi bi-circle"></i>
                      <span>Publication</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/blog">
                      <i className="bi bi-circle"></i>
                      <span>Blog</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink to="/admin/getInvolved">
                  <i className="bi bi-circle"></i>
                  <span>Get Involved</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/getInTouch">
                  <i className="bi bi-circle"></i>
                  <span>Contact Us Data</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/donation">
                  <i className="bi bi-circle"></i>
                  <span>Donation</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/donationdetails">
                  <i className="bi bi-circle"></i>
                  <span>Donation Detail</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/navImages">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Navbar Images</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/navbartext">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Navbar and Header Text</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/bannerimage">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Banner Images</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/socialLinks">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Social Links</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/FAQ">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>FAQ</span>
            </Link>
          </li>{" "}
          <li className="nav-item">
            <Link className="nav-link " to="/admin/termsOfService">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Terms Of Service</span>
            </Link>
          </li>{" "}
          <li className="nav-item">
            <Link className="nav-link " to="/admin/privacyPolicy">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Privacy Policy</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/quicklink">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Quick Link</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/admin/footer">
              <i style={{ fontSize: "10px" }} className="bi bi-circle"></i>
              <span>Footer</span>
            </Link>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link collapsed"
              data-bs-target="#newsletter-nav"
              data-bs-toggle="collapse"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>NewsLetters</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </NavLink>
            <ul
              id="newsletter-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <NavLink to="/admin/nwusers">
                  <i className="bi bi-circle"></i>
                  <span>NewsLetter Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/nwgroups">
                  <i className="bi bi-circle"></i>
                  <span>NewsLetter Groups</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/nwusersgroupmap">
                  <i className="bi bi-circle"></i>
                  <span>Mapping Users - Group </span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default AdminSidebar;
