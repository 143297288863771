import React, { useEffect, useState } from "react";
import { getDonationInfo } from "../../services/donationService";
import { toastPosition } from "../../config/toastProp";
import { toast, ToastContainer } from "react-toastify";
import { getDonationImage } from "../../services/api";
import Accessibility from "./Accessibility";
import khaltiLogo from "../../assets/img/img/khalti-logo.png";
import Khalti from "../shared/Khalti-Popup/Khalti-popup";
import { donateUs } from "../../services/NewsLetterUserService";
import { NavLink, useNavigate } from "react-router-dom";
import URLS from "../../urls/urls";
import globalGivingImage from "../../assets/img/img/GG_logo_stacked_440w.png";
import HimalayaBankLogo from "../../assets/img/img/himalayan-bank-logo.jpg";
import qr from "../../assets/img/img/qr.webp";
import nabilBankLogo from "../../assets/img/NabilBankLogo.jpg";
import siddarthaBankLogo from "../../assets/img/siddharthabanklimited_logo.jpeg";

const DonationHome = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [donationDatas, setDonationDatas] = useState({
    header: "",
    anyAmountCountHeader: "",
    bankName: "",
    location: "",
    swiftCode: "",
    iconDescs: [],
    currency: "",
    acNumber: "",
  });
  const [locale, setLocale] = useState("nep");
  const [donationImage, setDonationImage] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    amount: "",
  });

  useEffect(() => {
    const storedLocale = localStorage.getItem("locale") || "eng";
    setLocale(storedLocale === "eng" ? "eng" : "nep");
  }, []);

  useEffect(() => {
    async function fetchDonationInfo(field, loc) {
      try {
        const resp = await getDonationInfo(field + loc);
        const respData = resp.data.data;
        const fieldData = respData[field + loc];
        setDonationDatas((prevData) => ({ ...prevData, [field]: fieldData }));
      } catch (error) {
        toast.error(
          error.response?.data?.errormessage || "Error occurred",
          toastPosition
        );
      }
    }

    const fetchData = async () => {
      await fetchDonationInfo("iconDescs", "");
      const locSuffix = locale === "eng" ? "" : "Nepali";
      const fields = Object.keys(donationDatas).filter(
        (key) => key !== "iconDescs"
      );
      fields.forEach((field) => fetchDonationInfo(field, locSuffix));
      getDonationBannerImage();
    };

    fetchData();
  }, [locale]); // Added dependency

  const getDonationBannerImage = async () => {
    try {
      const response = await getDonationImage();
      const image = response.data.image;
      if (image) {
        setDonationImage(image);
      }
    } catch (error) {
      console.error("Error fetching donation image:", error);
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const generateRandomId = (prefix) => {
    return `${prefix}_${Math.random().toString(36).slice(2, 11)}`;
  };

  const payViaKhalti = async (customerData) => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      amount: "",
    });
    const formData = {
      return_url: `${URLS.BASE_URL}/api/newsLetter/donateUs`,
      website_url: `${URLS.HOST_URL}/donation`,
      amount: parseFloat(customerData.amount) * 100,
      purchase_order_id: generateRandomId("OrderId"),
      purchase_order_name: generateRandomId("OrderName"),
      customer_info: {
        name: customerData.name,
        email: customerData.email,
        phone: customerData.phone,
      },
    };
    const data = await donateUs(formData);
    console.log(data);
    if (data.responseData.payment_url) {
      toast.success(`going to payment page`, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      window.location.href = data.responseData.payment_url;
    } else {
      toast.error(`Something went wrong`, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    }
  };

  // const payViaHimalyanBank = async (customerData) => {
  //   setFormData({
  //     name: "",
  //     email: "",
  //     phone: "",
  //     amount: "",
  //   });
  //   const formData = {
  //     return_url: `${URLS.BASE_URL}/api/newsLetter/donateUs`,
  //     website_url: `${URLS.HOST_URL}/donation`,
  //     amount: parseFloat(customerData.amount) * 100,
  //     purchase_order_id: generateRandomId("OrderId"),
  //     purchase_order_name: generateRandomId("OrderName"),
  //     customer_info: {
  //       name: customerData.name,
  //       email: customerData.email,
  //       phone: customerData.phone,
  //     },
  //   };
  //   const data = await DonateUs(formData);
  //   console.log(data);
  //   if (data.responseData.payment_url) {
  //     toast.success(`going to payment page`, {
  //       position: "top-center",
  //       autoClose: 700,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: 0,
  //       theme: "colored",
  //     });
  //     window.location.href = data.responseData.payment_url;
  //   } else {
  //     toast.error(`Something went wrong`, {
  //       position: "top-center",
  //       autoClose: 700,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: 0,
  //       theme: "colored",
  //     });
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    payViaKhalti(formData);
    // payViaHimalyanBank(formData);
  };
  const handleHimalyanSubmit = (e) => {
    e.preventDefault();
    // payViaHimalyanBank(formData);
  };

  const buttonStyle = {
    marginLeft: "2px",
    marginRight: "2px",
    padding: 0,
    border: "none",
    background: "none",
    cursor: "pointer",
    opacity:
      formData.amount === "0" ||
      formData.amount === "" ||
      Number(formData.amount) < 10
        ? 0.4
        : 1,
  };

  return (
    <main>
      <section>
        <Accessibility />

        <div
          className="banner"
          style={{
            backgroundImage: `url(${
              donationImage || "https://creasion.org/storage/donate/banner.jpg"
            })`,
          }}
        >
          <div className="banner-content">
            <h1 className="text-white text-center">
              {locale === "eng" ? "Donation" : "दान"}
            </h1>
          </div>
        </div>

        <div className="row" style={{ marginRight: "0" }}>
          <div className="col-12 col-md-5">
            <div className="row my-5">
              {donationDatas.iconDescs.map((iconDesc, index) => (
                <div
                  key={index}
                  className="col-6 d-flex flex-column align-items-center"
                >
                  <img
                    src={iconDesc.icon}
                    alt=""
                    style={{
                      width: "100px",
                      height: "auto",
                      filter:
                        " saturate(100%) invert(74%) sepia(47%) saturate(402%) hue-rotate(150deg) brightness(82%) contrast(89%)",
                    }}
                  />
                  <h6 className="text-center">
                    {locale === "eng"
                      ? iconDesc.description
                      : iconDesc.descriptionNepali}
                  </h6>
                </div>
              ))}
            </div>
          </div>

          <div
            className="col-12 col-md-7"
            style={{ backgroundColor: "var(--lighter-grey)" }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="row">
                <div
                  className="col-12"
                  style={{ backgroundColor: "var(--lighter-grey)" }}
                >
                  <div className="my-5 d-flex flex-column align-items-center justify-content-center">
                    <h4>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: donationDatas.anyAmountCountHeader,
                        }}
                      ></span>
                    </h4>
                    <h1>International Donation</h1>
                    <div className="row my-2">
                      {/* <div className="col-12 col-md-4">
                        <div
                          className="card shadow-lg"
                          style={{ minWidth: "250px", minHeight: "100%" }}
                        >
                          <div className="card-body d-flex flex-column align-items-center justify-content-center">
                            <input
                              type="number"
                              placeholder="ANY AMOUNT COUNTS"
                              style={{ textAlign: "center" }}
                              id="amount"
                              value={formData.amount}
                              onChange={handleChange}
                            />
                            <p className="text-center mt-4">
                              <strong>Himalayan Bank</strong>
                            </p>
                            <NavLink
                              disabled={
                                formData.amount === "0" ||
                                formData.amount === "" ||
                                Number(formData.amount) < 10
                                  ? true
                                  : false
                              }
                              style={buttonStyle}
                              onClick={handleHimalyanSubmit}
                            >
                              <img
                                src={HimalayaBankLogo}
                                alt="Himalayan Bank Logo"
                                style={{ width: "180px", height: "auto" }}
                              />
                            </NavLink>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-12 col-md-6">
                        <div
                          className="card shadow-lg"
                          style={{ minWidth: "250px", minHeight: "100%" }}
                        >
                          <div className="card-body d-flex flex-column align-items-center justify-content-center">
                            <NavLink to="https://www.globalgiving.org/donate/28600/koshish/">
                              <img
                                src={globalGivingImage}
                                alt="Global Giving Logo"
                                style={{ width: "220px", height: "auto" }}
                              />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div
                          className="card shadow-lg"
                          style={{ minWidth: "160px", minHeight: "100%" }}
                        >
                          <div className="card-body d-flex flex-column align-items-center justify-content-center">
                            <img
                              style={{
                                height: "35px",
                                width: "auto",
                                borderRadius: "50%",
                              }}
                              src={nabilBankLogo}
                              alt="Nabil_Bank_logo"
                            />
                            <p className="text-center " style={{ margin: "0" }}>
                              <strong>Bank/Wire Transfer</strong>
                            </p>
                            <div className="text-left mt-2">
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Bank Name: Nabil Bank Limited
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Account Number: 3901017500074
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Account Name: KOSHISH
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Swift Code: NARBNPKA
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Bank Address: Kathmandu, Nepal
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1 className="mt-4">Local Donation</h1>
                    <div className="row my-3">
                      <div className="col-12 col-md-4">
                        <div
                          className="card shadow-lg"
                          style={{ minWidth: "250px", minHeight: "100%" }}
                        >
                          <div className="card-body d-flex flex-column align-items-center justify-content-center">
                            <input
                              type="number"
                              placeholder="ANY AMOUNT COUNTS"
                              style={{ textAlign: "center" }}
                              id="amount"
                              value={formData.amount}
                              onChange={handleChange}
                            />
                            <p className="text-center mt-4">
                              <strong>Khalti</strong>
                            </p>
                            <NavLink
                              disabled={
                                formData.amount === "0" ||
                                formData.amount === "" ||
                                Number(formData.amount) < 10
                                  ? true
                                  : false
                              }
                              style={buttonStyle}
                              onClick={handleSubmit}
                            >
                              <img src={khaltiLogo} alt="Khalti Logo" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div
                          className="card shadow-lg"
                          style={{ minWidth: "250px", minHeight: "100%" }}
                        >
                          <div className="card-body d-flex flex-column align-items-center justify-content-center">
                            <img
                              src={qr}
                              alt="QR Code"
                              style={{ width: "220px", height: "auto" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div
                          className="card shadow-lg"
                          style={{ minWidth: "160px", minHeight: "100%" }}
                        >
                          <div className="card-body d-flex flex-column align-items-center justify-content-center">
                            <img
                              style={{
                                height: "35px",
                                width: "auto",
                                borderRadius: "50%",
                              }}
                              src={siddarthaBankLogo}
                              alt="Siddartha_Bank_Logo"
                            />
                            <p className="text-center " style={{ margin: "0" }}>
                              <strong>Bank/Wire Transfer</strong>
                            </p>
                            <div className="text-left mt-2">
                              <span
                                style={{ fontSize: "11px", fontWeight: "600" }}
                              >
                                Bank Name: Siddartha Bank Limited
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Account Number: 01815222100
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Account Name: KOSHISH
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Swift Code: SIDDNPKA
                              </span>
                              <br />
                              <span
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                Bank Address: Kathmandu, Nepal
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </main>
  );
};

export default DonationHome;
