import React, { useEffect, useState } from "react";
import { getBannerImages, getOurPartner } from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import Accessibility from "./Accessibility";
import { NavLink } from "react-router-dom";

const AboutUsOurPartners = () => {
  const [aboutUsImages, setAboutUsImages] = useState();
  const [currentLocale, setCurrentLocale] = useState("EN");
  const [currentPartners, setCurrentPartners] = useState([]);
  const [formerPartners, setFormerPartners] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;
      if (resData) {
        setAboutUsImages(resData);
      }

      const response = await getOurPartner();
      if (response.data && response.data.partner) {
        // Separate partners into current and former based on the "type" field
        const partners = response.data.partner;
        const current = partners.filter((p) => p.type === "Current");
        const former = partners.filter((p) => p.type !== "Current"); // include "former" and those without a "type"

        setCurrentPartners(current);
        setFormerPartners(former);
      }
    } catch (error) {
      console.error("Failed to fetch partner data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const locale = localStorage.getItem("locale") === "eng" ? "EN" : "NP";
    setCurrentLocale(locale || "EN");
  }, []);

  return (
    <>
      <section className="about-us-section">
        <Accessibility />
        <div
          className="banner"
          style={{
            backgroundImage: `url(${
              aboutUsImages && aboutUsImages.aboutUsOurPartners
                ? aboutUsImages.aboutUsOurPartners
                : coverImage
            })`,
          }}
        >
          <div className="banner-content">
            <h1 className="text-white text-center">
              {currentLocale === "EN" ? "Our Partners" : "हाम्रा साझेदारहरू"}
            </h1>
          </div>
        </div>

        <div className="container">
          <div className="card-body">
            {/* Current Partners Section */}
            <h2 className="text-blue">
              {currentLocale === "EN" ? "Current Partners" : "हालका साझेदारहरू"}
            </h2>
            <div className="row mt-4">
              {currentPartners.length > 0 ? (
                currentPartners.map((item, index) => (
                  <div key={index} className="col-12 col-md-4 col-xl-2 mb-3">
                    <div className="team-member d-flex flex-column align-items-center">
                      <img
                        src={item.image}
                        alt="team member"
                        className="img-fluid our-team-image"
                      />
                      <NavLink
                        to={`/ourPartner/readMore/${item._id}`}
                        className="text-blue mb-2"
                      >
                        {currentLocale === "EN" ? "Read More" : "थप पढ्नुहोस्"}{" "}
                        <i className="fas fa-circle-arrow-right ml-2"></i>
                      </NavLink>
                    </div>
                  </div>
                ))
              ) : (
                <p>
                  {currentLocale === "EN"
                    ? "No Current Partners"
                    : "हालका साझेदारहरू छैनन्"}
                </p>
              )}
            </div>

            {/* Former Partners Section */}
            <h2 className="text-blue">
              {currentLocale === "EN" ? "Former Partners" : "पूर्व साझेदारहरू"}
            </h2>
            <div className="row mt-4">
              {formerPartners.length > 0 ? (
                formerPartners.map((item, index) => (
                  <div key={index} className="col-12 col-md-4 col-xl-2 mb-3">
                    <div className="team-member d-flex flex-column align-items-center">
                      <img
                        src={item.image}
                        alt="team member"
                        className="img-fluid our-team-image"
                      />
                      <NavLink
                        to={`/ourPartner/readMore/${item._id}`}
                        className="text-blue mb-2"
                      >
                        {currentLocale === "EN" ? "Read More" : "थप पढ्नुहोस्"}{" "}
                        <i className="fas fa-circle-arrow-right ml-2"></i>
                      </NavLink>
                    </div>
                  </div>
                ))
              ) : (
                <p>
                  {currentLocale === "EN"
                    ? "No Former Partners"
                    : "पूर्व साझेदारहरू छैनन्"}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsOurPartners;
